import { Router } from '@reach/router';
import { PageProps } from 'gatsby';
import React from 'react';
import ContactPage from './contact';
import HomePage from './home';

const IndexPage: React.FC<PageProps> = (props) => {
  return (
    <Router>
      <HomePage {...props} path="/" />
      <ContactPage {...props} path="/contact" />
    </Router>
  );
};

export default IndexPage;
